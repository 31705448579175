import { useSearchParams } from "react-router-dom";
import { usePerformanceReport } from "../../../../hooks";
import { SortLimitForm } from "./MetricParameters";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const Top30LCPByURI = () => {
    const [searchParams] = useSearchParams();
    const report = "lcp_by_uri";
    const prefix = `top_30_${report}`;
    const metricParams = {
        sort: searchParams.get(`${prefix}_sort`) ?? "relative_acceleration",
        limit_direction: searchParams.get(`${prefix}_limit_direction`) ?? "DESC",
        limit: searchParams.get(`${prefix}_limit`) ?? 30,
        limit_by: searchParams.get(`${prefix}_limit_by`) ?? "relative_acceleration",
    };

    const { data, isLoading, isError } = usePerformanceReport({ report, ...metricParams });

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
            <SortLimitForm prefix={report} params={metricParams} />
        </RenderMetric>
    );
};
