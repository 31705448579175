import { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { UseQueryResult } from "@tanstack/react-query";
import { useStore } from "..";
import { SVC_URLS } from "../../config/SVC_URLS";
import { Store } from "../../types";
import { fetchOptions, handleErrors, fetchResponse, queryParameters } from "../../lib";

export const useRevenueReport = (reportParams: any) => {
    const [searchParams] = useSearchParams();
    const storeQuery = useStore();
    const { store } = useData(storeQuery);
    const unfilteredParams = Object.fromEntries(searchParams.entries());
    const globalParams = (({ from_hour, upto_hour, sale_strategy, percentile, attribution }) => ({
        from_hour,
        upto_hour,
        sale_strategy,
        percentile,
        attribution,
    }))(unfilteredParams);
    const allParams = { ...globalParams, ...reportParams };

    const params = queryParameters(allParams);

    const enabled =
        store !== undefined &&
        searchParams.get("from_hour") !== null &&
        searchParams.get("upto_hour") !== null &&
        searchParams.get("sale_strategy") !== null &&
        searchParams.get("attribution") !== null &&
        searchParams.get("percentile") !== null;

    return useQuery({
        queryKey: ["revenue-reports", store?.id, params],
        queryFn: () => fetchReports(store, params),
        enabled,
        refetchOnWindowFocus: false,
    });
};

const fetchReports = async (store: Store | undefined, params: string) => {
    const endpoint = `${SVC_URLS["portal"]}/reports/revenue/?${params}`;
    const options = await fetchOptions({
        headers: { Store: store!.customerUrl },
    });
    const response = await fetch(endpoint, options).catch(handleErrors);
    return fetchResponse(response);
};

const useData = (storeQuery: UseQueryResult<Store, unknown>) => {
    const showToast = useToast({
        duration: 5000,
        isClosable: true,
        variant: "subtle",
    });
    const store = storeQuery.data;
    const isLoading = storeQuery.isLoading;
    const isError = storeQuery.isError;

    useEffect(() => {
        if (isError) {
            showToast({
                title: "Error",
                description:
                    "There was an error loading this page. Please try again in a few moments. Contact support if the problem persists.",
                status: "error",
            });
        }
    }, [isError, showToast]);

    return {
        store,
        isLoading,
    };
};
