import { Divider } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { Header } from "../../../../components";
import { ReportParameters } from "./ReportParameters";
import { OrderTaggingCoverageByInterval } from "./OrderTaggingCoverageByInterval";
import { OrderTaggingCoverage } from "./OrderTaggingCoverage";
import { SessionCount } from "./SessionCount";
import { AbsoluteCumulativeSessionCount } from "./AbsoluteCumulativeSessionCount";
import { RelativeCumulativeSessionCount } from "./RelativeCumulativeSessionCount";
import { CVR } from "./CVR";
import { AOV } from "./AOV";
import { RPS } from "./RPS";
import { Humps } from "./Humps";
import { NewVisitorCount } from "./NewVisitorCount";

const capitalize = (s: string | null) => (s && String(s[0]).toUpperCase() + String(s).slice(1)) || "";

export const RevenueReport = () => {
    const [searchParams] = useSearchParams();
    const enabled =
        searchParams.get("from_hour") !== null &&
        searchParams.get("upto_hour") !== null &&
        searchParams.get("sale_strategy") !== null &&
        searchParams.get("percentile") !== null &&
        searchParams.get("attribution") !== null;

    return (
        <>
            <ReportParameters />
            {enabled && (
                <>
                    <Header style={{ marginTop: "20px" }}>Hump Chart</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <Humps />
                    <Header style={{ marginTop: "20px" }}>[Check] Cart Tagging (Order Attribution)</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <OrderTaggingCoverageByInterval />
                    <OrderTaggingCoverage />
                    <Header style={{ marginTop: "20px" }}>{`[Check] Test Splitter (Asymmetric ${capitalize(
                        searchParams.get("attribution"),
                    )} Generation)`}</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <SessionCount />
                    {searchParams.get("attribution") === "visitors" && (
                      <NewVisitorCount />
                    )}
                    <AbsoluteCumulativeSessionCount />
                    <RelativeCumulativeSessionCount />
                    <Header style={{ marginTop: "20px" }}>[Customer] CVR</Header>
                    <Divider style={{ margin: "20px 0" }} />
                    <CVR />
                    <AOV />
                    <RPS />
                </>
            )}
        </>
    );
};
